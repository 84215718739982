import { getDownloadURL, ref } from 'firebase/storage'

import { callHttpsCallableFunction, storage } from '../api/firebase'

import MeasurementEntity from '../entities/measurement.entity'

import Controller from './base.controller'

import { cF } from '../utils/cloud-functions'

const collection = 'measurements'

/**
 * Class responsible for keeping all the measurement logic.
 */
export default class MeasurementController extends Controller {
  /**
   * Gets an image URL from the storage.
   *
   * @param {string} path the storage child path.
   * @return the image URL.
   */
  async getImageUrl(path) {
    return getDownloadURL(ref(storage, path))
  }

  /**
   * Gets a document data that represents the found measurement.
   *
   * @example
   * ```
   * const data = new MeasurementController().getOne('measurements/1234567890abcdef')
   * data.id => '1234567890abcdef'
   * ```
   *
   * @param {string} path the measurement document path.
   * @returns a measurement entity.
   */
  async getOne(path) {
    const doc = await super.getById(path.split('/'))

    return MeasurementEntity.fromFirestore({ ...doc.data(), id: doc.id })
  }

  /**
   * Listens for changes in the measurements collection.
   *
   * @param {string} path the measurements collection path.
   * @param {(measurements: MeasurementEntity[]) => void} callback a callback to be called when the collection is triggered.
   */
  listenMultiple(path, callback) {
    super.listenMultiple(path.split('/'), (snap) => {
      if (snap.empty) {
        return void callback(null)
      }

      const docs = snap.docs.map((m) =>
        MeasurementEntity.fromFirestore({ ...m.data(), id: m.id }),
      )

      callback(docs)
    })
  }

  /**
   * Listens for changes into some measurement.
   *
   * @param {string} path the path to the measurement document.
   * @param {(measurement?: MeasurementEntity) => void} callback a callback to be called when the document is triggered.
   */
  listenOne(path, callback) {
    super.listen(path.split('/'), (document) => {
      if (!document.exists()) {
        return void callback(null)
      }

      callback(
        MeasurementEntity.fromFirestore({
          ...document.data(),
          id: document.id,
        }),
      )
    })
  }

  /**
   * Gets all database measurements according to the given constraints.
   *
   * @param {string} parent the parent path.
   * @returns a list of measurements.
   * @throws a Firebase exception.
   */
  async getAll(parent) {
    const path = parent ? parent.split('/') : []

    if (!path.includes(collection)) {
      path.push(collection)
    }

    const snapshot = await super.getAll(path)

    return snapshot.docs.map((m) =>
      MeasurementEntity.fromFirestore({ ...m.data(), id: m.id }),
    )
  }

  /**
   * Updates a measurement with the given data and id.
   *
   * @param {string} id the measurement id.
   * @param {MeasurementEntity} data the measurement data to be updated.
   */
  async update(id, data) {
    await super.update([collection, id], data)

    return new MeasurementEntity({ ...data, id })
  }

   /**
   * Updates a measurement with the given data and id.
   *
   * @param {string} id the measurement id.
   * @param {MeasurementEntity} data the measurement data to be updated.
   */
   async updateConsumer(path, data) {
    await super.update(path, data)

    return new MeasurementEntity({ ...data })
  }

  /**
   * Deletes a measurement from the database.
   *
   * @param {string} path the measurement document reference.
   * @throws a Firebase exception.
   */
  async delete(path) {
    await super.delete(path)
  }

  /**
   * Computes the `results` from the measurement that represents the
   * given `id`.
   *
   * @param {string} id the measurement id.
   * @throws an Error or FirebaseException.
   */
  async computeMeasurement(id) {
    const measurementDoc = await this.getOne(id)

    if (!measurementDoc.exists()) {
      throw new Error('not-found')
    }

    const measurement = measurementDoc.data()

    if (!measurement.measurement_progress) {
      throw new Error('null-measurement-progress')
    }

    const results = await callHttpsCallableFunction(
      cF.computeMeasurement,
      measurement.measurement_progress,
    )

    if (results.data.error) {
      throw new Error('error-computing-measurement')
    }

    await this.update(id, {
      ...measurement,
      results: results.data.data ?? results.data,
    })
  }
}
