<template>
  <div>
    <!-- quotas -->
    <quotas />

    <!-- bar chart -->
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('admin.stats.sectionTitle1') }}</b-card-title>
        <!-- datepicker -->
        <b-col cols="4">
          <v-select v-model="year" :options="years" :clearable="false" />
        </b-col>
        <!-- datepicker -->
      </b-card-header>
      <b-card-body>
        <chartjs-component-bar-chart
          :height="400"
          :chart-data="data"
          :options="options"
        />
      </b-card-body>
    </b-card>
    <!-- bar chart -->

    <!-- doughnut chart -->
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('admin.stats.sectionTitle2') }}</b-card-title>
      </b-card-header>
      <b-card-body>
        <vue-apex-charts
          type="donut"
          height="350"
          :options="doughnutOptions"
          :series="doughnutData"
        />
      </b-card-body>
    </b-card>
    <!-- doughnut chart -->

    <!-- measurement doughnut chart -->
    <b-card v-if="$store.state.auth.user.role === 2" no-body>
      <b-card-header>
        <b-card-title>{{ $t('admin.stats.sectionTitle4') }}</b-card-title>
      </b-card-header>

      <b-card-body>
        <vue-apex-charts
          type="donut"
          height="350"
          :options="{
            ...doughnutOptions,
            colors: ['#00d4bd', '#04679e'],
            labels: [
              $t('admin.stats.measurementsDone'),
              $t('admin.stats.sectionField7'),
            ],
          }"
          :series="[userDoc.qtyMeasurements, userDoc.allowedMeasurements]"
        />
      </b-card-body>
    </b-card>
    <!-- measurement doughnut chart -->

    <!-- area chart -->
    <b-card v-if="$store.state.auth.user.role === 3" no-body>
      <b-card-header>
        <b-card-title>{{ $t('admin.stats.sectionTitle3') }}</b-card-title>
        <b-col cols="4">
          <v-select
            v-model="lineYear"
            :options="lineYears"
            :clearable="false"
            placeholder="Select a year"
          />

          <flat-pickr
            class="form-control mb-1 mt-1"
            :config="configs.monthSelect"
            v-model="month"
            placeholder="Pick a month"
          />
        </b-col>
      </b-card-header>
      <b-card-body>
        <vue-apex-charts
          type="area"
          height="400"
          :options="lineAreaChartSplineOptions"
          :series="lineSeries"
          ref="area"
        />
      </b-card-body>
    </b-card>
    <!-- area chart -->
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BCol,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

import { onUnmounted } from 'vue-demi'
import store from '@/store'

import statsStoreModule from './statsStoreModule'
import useStatsList from './useStatsList'

import ChartjsComponentBarChart from '@/components/ChartjsComponentBarChart.vue'
import VueApexCharts from 'vue-apexcharts'

import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'

import Quotas from './Quotas.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
    BCol,

    ChartjsComponentBarChart,

    VueApexCharts,
    Quotas,
    vSelect,
  },

  data() {
    return {
      month: '',
      configs: {
        monthSelect: {
          plugins: [
            new monthSelectPlugin({
              shorthand: true,
              dateFormat: 'm/Y',
            }),
          ],
        },
      },
    }
  },

  computed: {
    userDoc() {
      return this.$store.state.auth.userAdminDoc
    },
    data() {
      return store.state.stats.data
    },
    options() {
      return store.state.stats.options
    },
    doughnutData() {
      return store.state.stats.doughnutData
    },
    doughnutOptions() {
      return store.state.stats.doughnutOptions
    },
    years() {
      return store.state.stats.years
    },
    year: {
      get() {
        return store.state.stats.year
      },
      set(value) {
        store.dispatch('stats/setYear', value)
      },
    },
    lineYears() {
      return store.state.stats.lineYears
    },
    lineYear: {
      get() {
        return store.state.stats.lineYear
      },
      set(value) {
        store.dispatch('stats/setLineYear', value)
      },
    },
    lineSeries() {
      return store.state.stats.lineSeries
    },
    lineAreaChartSplineOptions() {
      return store.state.stats.lineAreaChartSplineOptions
    },
  },

  watch: {
    year() {
      this.measurementsCount(
        this.year,
        store.state.auth.user.adminDocRef || store.state.auth.user.sellerDocRef,
      )
    },
    lineYear() {
      this.month = ''
      if (this.lineYear !== '') {
        this.sellersMeasurementsCount(
          this.lineYear,
          null,
          store.state.auth.user.adminDocRef,
        )
        this.$refs.area.updateOptions({
          xaxis: {
            categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
          },
        })
      }
    },
    month() {
      this.lineYear = ''
      if (this.month !== '') {
        const monthYear = this.month.split('/')
        this.sellersMeasurementsCount(
          null,
          monthYear,
          store.state.auth.user.adminDocRef,
        )
        this.$refs.area.updateOptions({
          xaxis: {
            categories: Array.from({ length: 31 }, (_, i) => i + 1),
          },
        })
      }
    },
  },

  setup() {
    const STATS_APP_STORE_MODULE_NAME = 'stats'

    if (!store.hasModule(STATS_APP_STORE_MODULE_NAME)) {
      store.registerModule(STATS_APP_STORE_MODULE_NAME, statsStoreModule)
    }

    onUnmounted(
      () =>
        store.hasModule(STATS_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(STATS_APP_STORE_MODULE_NAME),
    )

    store.dispatch('stats/measurementsCount', {
      year: store.state.stats.year,
      parent:
        store.state.auth.user.adminDocRef || store.state.auth.user.sellerDocRef,
    })
    store.dispatch(
      'stats/measurementsPercent',
      store.state.auth.user.adminDocRef || store.state.auth.user.sellerDocRef,
    )
    store.dispatch('stats/sellersMeasurementsCount', {
      year: null,
      monthYear: null,
      parent: store.state.auth.user.adminDocRef,
    })

    const { measurementsCount, sellersMeasurementsCount } = useStatsList()

    return {
      measurementsCount,
      sellersMeasurementsCount,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
