<template>
  <div>
    <div v-if="myProduct || myExternalProduct">
      <b-row align-h="between">
        <b-col>
          <b-card class="text-center">
            <h2>
              {{ `${userDoc.qtyMeasurements}/${allowedMeasurements}` }}
            </h2>
            <p>{{ $t('admin.stats.measurementsDone') }}</p>
          </b-card>
        </b-col>
        <b-col>
          <b-card class="text-center">
            <h2>
              {{ `${userDoc.availableMeasurements}` }}
            </h2>
            <p>{{ $t('admin.stats.unassignedMeasurements') }}</p>
          </b-card>
        </b-col>
        <b-col v-if="isOpticAdmin">
          <b-card class="text-center">
            <h2>{{ `${userDoc.activeSellers}/${allowedSellers}` }}</h2>
            <p>{{ $t('admin.stats.activeSellers') }}</p>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-else-if="!isPlanParent">
      <b-card class="text-center" v-if="userDoc">
        <h2>
          {{ `${userDoc.qtyMeasurements}/${userDoc.allowedMeasurements}` }}
        </h2>
        <p>{{ $t('admin.stats.measurementsDone') }}</p>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import Controller from '../../../controllers/base.controller'
import RoleEnum from '@/utils/roles'
import { onUnmounted } from 'vue-demi'
import planStoreModule from '@/views/admin/plans/planStoreModule'
import store from '@/store'

export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      myProduct: null,
      mainProduct: null,
      allowedMeasurements: '∞',
      allowedSellers: '∞',
      myExternalProduct: null,
    }
  },
  computed: {
    isSeller() {
      return this.user.role === RoleEnum.SELLER ? true : false
    },
    isOpticAdmin() {
      return this.user.role === RoleEnum.OPTIC_ADMIN ? true : false
    },
    user() {
      return this.$store.state.auth.user
    },
    userDoc() {
      return this.$store.state.auth.userAdminDoc
    },
    isPlanParent() {
      if (this.user.role === RoleEnum.SELLER) {
        return false
      } else {
        const planParent =
          this.user.adminDocRef.split('/').length > 2 ? false : true

        if (!planParent) {
          return false
        }
        return true
      }
    },
    externalPlans() {
      return this.$store.state.plan.plans_lensxpert || []
    },
  },
  async mounted() {
    if (this.user.plan && this.user.plan.type == 'external') {
      await this.$store.dispatch('plan/fetchPlansLensxpert')
      this.myExternalProduct = this.externalPlans.find(
        (el) => el.id === this.user.plan.id,
      )

      this.allowedMeasurements = this.myExternalProduct.measurements
      this.allowedSellers = this.myExternalProduct.sellers
    } else {
      const subscriptionSnap = await new Controller().getAll([
        'customers',
        this.user.id,
        'subscriptions',
      ])
      if (!subscriptionSnap.size) {
        return
      }
      const mySubscription = subscriptionSnap.pop().data()
      const mainProduct = await new Controller().getWithRef(
        mySubscription.product,
      )
      const myProduct = await new Controller().getWithRef(
        mySubscription.prices[0],
      )
      this.myProduct = myProduct.data()
      this.mainProduct = mainProduct.data()

      this.allowedMeasurements = this.myProduct.metadata.allowedMeasurements
      this.allowedSellers = this.myProduct.metadata.allowedSellers
    }
  },

  setup() {
    const PLAN_APP_STORE_MODULE_NAME = 'plan'

    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) {
      store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    }

    onUnmounted(() => {
      store.hasModule(PLAN_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(PLAN_APP_STORE_MODULE_NAME)
    })
  },
}
</script>
