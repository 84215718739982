import MeasurementController from '../../../controllers/measurement.controller'
import SellerController from '../../../controllers/seller.controller'

import { $themeColors } from '@themeConfig'

import apexChartData from './apexChartData'
import chartjsData from './chartjsData'

const measurementController = new MeasurementController()
const sellerController = new SellerController()

export default {
  namespaced: true,
  state: {
    data: {},
    doughnutData: [],
    lineSeries: [],
    lineAreaChartSplineOptions: apexChartData.lineAreaChartSpline.options,
    years: [],
    year: '',
    lineYears: [],
    lineYear: '',
    doughnutOptions: apexChartData.donutChart.chartOptions,
    options: chartjsData.latestBarChart.options,
  },
  getters: {},
  mutations: {
    SET_DATA(state, data) {
      state.data = data
    },
    SET_YEARS(state, data) {
      state.years = data
    },
    SET_YEAR(state, data) {
      state.year = data
    },
    SET_LINEYEARS(state, data) {
      state.lineYears = data
    },
    SET_LINEYEAR(state, data) {
      state.lineYear = data
    },
    SET_BAR_MAXY(state, data) {
      data % 2 == 0
        ? (state.options.scales.yAxes[0].ticks.max = data + 2)
        : (state.options.scales.yAxes[0].ticks.max = data + 1)
    },
    SET_DOUGHNUTDATA(state, data) {
      state.doughnutData = data
    },
    SET_LINESERIES(state, data) {
      state.lineSeries = data
    },
  },
  actions: {
    async measurementsOfCurrentMonth({ commit }, payload) {
      function Last7Days() {
        var result = []
        for (var i = 0; i < 7; i++) {
          var d = new Date()
          d.setDate(d.getDate() - i)
          result.push(d)
        }

        return result
      }

      const series = []
      const year = new Date().getFullYear()
      const month = new Date().getMonth()
      const measurements = await measurementController.getAll(payload.parent)
      const dayCountArr = Array(7).fill(0)
      const last7days = Last7Days()

      measurements.forEach((measurement) => {
        let date = new Date(measurement.createdAt)
        if (isNaN(date)) date = new Date(measurement.created_at)

        const index = last7days.findIndex(
          (el) =>
            el.getFullYear() == year &&
            el.getMonth() == month &&
            el.getDay() == date.getDay(),
        )
        if (index !== -1) {
          dayCountArr[index] += 1
        }
      })
      series.push({
        name: payload.name,
        data: dayCountArr,
      })
      commit('SET_LINESERIES', series)
    },
    async measurementsCount({ commit }, payload) {
      try {
        const years = []
        const measurements = await measurementController.getAll(payload.parent)

        if (!payload.year) {
          if (measurements[0]) {
            let date = new Date(measurements[0].createdAt)
            if (isNaN(date)) date = new Date(measurements[0].created_at)
            payload.year = date.getFullYear()
          } else {
            payload.year = new Date().getFullYear()
          }
        }

        const monthCountArr = Array(12).fill(0)
        measurements.forEach((measurement) => {
          let date = new Date(measurement.createdAt)
          if (isNaN(date)) date = new Date(measurement.created_at)
          const year = date.getFullYear()
          if (year) {
            if (!years.includes(year)) years.push(year)
            if (year == payload.year) {
              monthCountArr[date.getMonth()] += 1
            }
          }
        })

        const data = {
          labels: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          datasets: [
            {
              label: 'Measurements',
              backgroundColor: $themeColors.primary,
              borderColor: $themeColors.primary,
              borderWidth: 1,
              data: monthCountArr,
            },
          ],
        }

        commit('SET_DATA', data)
        commit('SET_YEAR', payload.year)
        commit('SET_YEARS', years)
        commit('SET_BAR_MAXY', Math.max(...monthCountArr))
      } catch (err) {
        throw err
      }
    },
    async measurementsPercent({ commit }, payload) {
      try {
        const measurements = await measurementController.getAll(payload)
        let simpleVision = 0
        let progressive = 0
        let pantoscopicAngle = 0
        let creditCard = 0

        measurements.forEach((measurement) => {
          if (measurement.measurement_progress) {
            measurement.measurement_progress.measurements.complete.forEach(
              (compl) => {
                if (compl === 'glasses front dnp') {
                  simpleVision += 1
                }
                if (compl === 'glasses front complete') {
                  progressive += 1
                }
                if (compl === 'glasses side complete') {
                  pantoscopicAngle += 1
                }
                if (compl === 'card card complete') {
                  creditCard += 1
                }
              },
            )
          }
        })

        const percents = []
        percents.push(simpleVision)
        percents.push(progressive)
        percents.push(pantoscopicAngle)
        percents.push(creditCard)

        commit('SET_DOUGHNUTDATA', percents)
      } catch (err) {
        throw err
      }
    },
    async sellersMeasurementsCount({ commit }, payload) {
      const series = []
      const years = []

      const monthCountArr = Array(12).fill(0)
      const dayCountArr = Array(31).fill(0)

      let hasMonth = payload.monthYear !== null ? true : false

      try {
        if (hasMonth) payload.year = payload.monthYear[1]

        const sellers = await sellerController.getAll(payload.parent)
        if (sellers.length > 0) {
          sellers.forEach(async (seller) => {
            const parent = payload.parent + '/sellers/' + seller.id
            const measurements = await measurementController.getAll(parent)
            //const measurements = [{createdAt: 'Fri Aug 05 2022'}, {createdAt: 'Fri Sep 10 2021'}]
            if (measurements.length > 0) {
              if (payload.year === null) {
                let date = new Date(measurements[0].createdAt)
                if (isNaN(date)) date = new Date(measurements[0].created_at)
                payload.year = date.getFullYear()
              }

              measurements.forEach((measurement) => {
                let date = new Date(measurement.createdAt)
                if (isNaN(date)) date = new Date(measurement.created_at)
                const year = date.getFullYear()
                if (!years.includes(year)) years.push(year)
                if (year === parseInt(payload.year)) {
                  const month = date.getMonth() + 1
                  if (hasMonth) {
                    if (month === parseInt(payload.monthYear[0])) {
                      dayCountArr[date.getDate() - 1] += 1
                    }
                  } else {
                    monthCountArr[month - 1] += 1
                  }
                }
              })

              series.push({
                name: seller.nameRegistered,
                data: hasMonth ? dayCountArr : monthCountArr,
              })
            }
          })

          commit('SET_LINESERIES', series)

          if (!hasMonth) {
            commit('SET_LINEYEAR', payload.year)
            commit('SET_LINEYEARS', years)
          }
        }
      } catch (err) {
        throw err
      }
    },
    setYear({ commit }, payload) {
      commit('SET_YEAR', payload)
    },
    setLineYear({ commit }, payload) {
      commit('SET_LINEYEAR', payload)
    },
  },
}
