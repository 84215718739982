import store from '@/store'

export default function useStatsList() {

  const measurementsCount = (year, parent) => {
    store.dispatch('stats/measurementsCount', {
      year: year,
      parent: parent
    })
  }

  const sellersMeasurementsCount = (year, monthYear, parent) => {
    store.dispatch('stats/sellersMeasurementsCount', {
      year: year,
      monthYear: monthYear,
      parent: parent
    })
  }

  return {
    measurementsCount,
    sellersMeasurementsCount
  }
}