import i18n from '@/libs/i18n'

// colors
const chartColors = {
  donut: {
    series1: '#9146FF',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#2b9bf4',
    series2: '#826bf8',
    series1: '#FFA1A1',
  },
}

export default {
  donutChart: {
    chartOptions: {
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      colors: [
        chartColors.donut.series1,
        chartColors.donut.series5,
        chartColors.donut.series3,
        chartColors.donut.series2,
      ],
      dataLabels: {
        enabled: true,
        formatter(val) {
          // eslint-disable-next-line radix
          return `${parseInt(val)}%`
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '2rem',
                fontFamily: 'Montserrat',
              },
              value: {
                fontSize: '1rem',
                fontFamily: 'Montserrat',
                formatter(val) {
                  // eslint-disable-next-line radix
                  return `${parseInt(val)}`
                },
              },
              total: {
                show: true,
                fontSize: '1.5rem',
                label: 'Total',
                formatter(w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b
                  }, 0)
                },
              },
            },
          },
        },
      },
      labels: [
        i18n.t('admin.stats.sectionField3'),
        i18n.t('admin.stats.sectionField4'),
        i18n.t('admin.stats.sectionField5'),
        i18n.t('admin.stats.sectionField6'),
      ],
    },
  },

  lineAreaChartSpline: {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
        curve: 'straight',
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        // opposite: isRtl
      },
      fill: {
        opacity: 1,
        type: 'solid',
      },
      tooltip: {
        shared: false,
      },
      colors: [
        chartColors.area.series3,
        chartColors.area.series2,
        chartColors.area.series1,
      ],
    },
  },
}
